<template>
  <div class="resignation-status-info">
    <div v-if="resignation.confirmationReceivedDate">
      <font-awesome-icon icon="check" />
      Your resignation is confirmed!
    </div>
    <div v-else-if="resignation.submittedToMembershipDate">
      Your resignation has been sent to church headquarters
    </div>
    <div v-else-if="resignation.attorneyReviewedDate">
      We have reviewed your resignation. It will go to church headquarters soon
    </div>
    <div v-else-if="resignation.userApprovedResignationDate">
      <!--User Approved-->
      
      <font-awesome-icon icon="arrow-alt-circle-right" />
      Submitted! We'll review your resignation soon
    </div>
    <div v-else>
      <font-awesome-icon icon="info-circle" />
      Your resignation has not been submitted
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResignationStatusInfo',
  props: {
    resignation: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
