<template>
  <b-container class="body-container">
    <h1>Errors</h1>

    <loader message="Loading errors..."
            :show="!resignation" />

    <div v-if="!resignation.userApprovedResignationDate"
         class="mb-3">
      <div class="bg-warning p-3">
        <font-awesome-icon icon="exclamation-triangle" />
        Once all errors have been resolved, you must approve your resignation again before it will be processed!
      </div>
    </div>

    <div v-if="resignation">
      <resignation-error-list :resignation-errors="resignation.resignationErrors"
                              :resignation-id="this.resignationId"
                              @resignation-error-resolved="onResignationErrorResolved" />
    </div>

    <div v-if="!resignation.errorFlag"
         class="mt-3">
      <h2>All errors have been resolved</h2>

      <b-button :to="{ name: 'resignation-edit', params: { resignationId: resignation.resignationId} }"
                variant="primary">
        Continue Resignation
      </b-button>
    </div>

  </b-container>
</template>

<script>
import ResignationErrorList from '@/components/ResignationErrorList';
import resignationMixins from '@/mixins/resignationMixins.ts';
import ResignationStatusInfo from '@/components/ResignationStatusInfo';

export default {
  name: 'ResignationError',
  mixins: [resignationMixins],
  components: { ResignationStatusInfo, ResignationErrorList },
  data() {
    return {};
  },
  mounted() {
    this.parseRouteParams();
    if (this.resignationId) {
      this.getResignation(this.resignationId);
    }
  },
  methods: {
    onResignationErrorResolved() {
      this.getResignation(this.resignationId);
    }
  }
};
</script>

<style scoped>

</style>
