import { render, staticRenderFns } from "./ResignationStatusInfo.vue?vue&type=template&id=6baa12be&scoped=true&"
import script from "./ResignationStatusInfo.vue?vue&type=script&lang=js&"
export * from "./ResignationStatusInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6baa12be",
  null
  
)

export default component.exports